<template>
  <div class="refund-list">
    <en-table-layout :tableData="pageData.data" :loading="loading">
      <template slot="header">
        <el-tabs v-model="advancedForm.refund_status" type="card" @tab-click="advancedSearchEvent">
          <el-tab-pane v-for="(item, index) in refundstatusList" :key="index"
            :label="item.label + templateOrdernum(item.label)" :name="item.value" :disabled="loading"></el-tab-pane>
        </el-tabs>
      </template>
      <template slot="toolbar">
        <el-form-item label="售后类型" class="col-auto">
          <el-select style="width: 120px;" size="medium" v-model="advancedForm.refuse_type" placeholder="请选择" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option label="仅退款" value="RETURN_MONEY"></el-option>
            <el-option label="商家退款" value="SHOP_REFUND"></el-option>
            <el-option label="退款退货" value="RETURN_GOODS"></el-option>
            <el-option label="换货" value="EXCHANGE_GOODS"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="关键词" class="col-auto">
          <el-input style="width: 320px" v-model.trim="advancedForm.searchText" size="medium" placeholder="请输入关键字进行搜索"
            clearable>
            <el-select slot="prepend" style="width: 120px" size="medium" v-model="advancedForm.keyWord"
              placeholder="请选择" clearable>
              <el-option label="售后单号" value="sn"></el-option>
              <el-option label="订单编号" value="trade_sn"></el-option>
              <el-option label="下单账号" value="mobile_account"></el-option>
              <el-option label="商品名称" value="goods_name"></el-option>
              <!-- <el-option label="所属企业" value="enterprise_name"></el-option> -->
            </el-select>
          </el-input>
        </el-form-item>

        <div class="col-auto">
          <el-button @click="advancedSearchEvent" size="small" type="primary">
            搜索
          </el-button>
        </div>
        <div class="col"></div>
        <div class="col-auto">
          <el-button @click="submitImport" :loading="importLoading" size="small" type="primary">
            导出退款订单
          </el-button>
        </div>
      </template>
      <template slot="table-columns">
        <el-table-column label="图片" width="76" fixed="left" class-name="goods-cover-wrapper">>
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.goods_image" alt="" style="width: 250px; height: 250px;">
              <router-link :to="'/shop-goods/good?&goods_id=' + row.goods_id" slot="reference">
                <img :src="row.goods_image" class="goods-cover" alt="" />
              </router-link>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="商品名称" min-width="200" :show-overflow-tooltip="true" fixed="left">
          <template slot-scope="{row}">
            {{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
          </template>
        </el-table-column>

        <el-table-column prop="sn" label="售后单号" width="140" />
        <!--订单号-->
        <el-table-column label="订单编号" width="180">
          <template slot-scope="scope">
            <div>
              {{ scope.row.trade_sn }}
            </div>
          </template>
        </el-table-column>
        <!--会员名称-->
        <el-table-column label="下单账号" prop="mobile" width="150" class-name="font-family-tttgb">
          <template slot-scope="{row}">
            <div v-if="row.mobile && row.login_account">
              <p>{{ row.mobile }}</p>
              {{ row.login_account }}
            </div>
            <div v-else>
              {{ row.mobile }}
              {{ row.login_account }}
            </div>
          </template>
        </el-table-column>
        <!--所属企业-->
        <el-table-column label="所属站点" min-width="120" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.shop_name }}
          </template>
        </el-table-column>
        <!--创建时间-->
        <el-table-column label="申请时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <!--类型-->
        <el-table-column prop="refuse_type_text" label="类型" width="100px" fixed="right" />
        <!--状态-->
        <el-table-column prop="refund_status_text" label="状态" width="100px" fixed="right">
          <template slot-scope="scope">
            <span>{{ scope.row.refund_status_text }}</span>
            <div class="refund-fail-reason" v-if="scope.row.refund_status === 'REFUNDFAIL'"
              @click="showRefundFailReason(scope.row)">
              (退款失败原因)
            </div>
            <div v-if="scope.row.refund_reason === '线下售后'">
              <span style="color: red;">(京东自动退回)</span>
            </div>
            <div v-if="scope.row.refund_reason === '京东取消订单'">
              <span style="color: red;">(京东取消订单)</span>
            </div>
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="300" fixed="right">
          <template slot-scope="{row}">
            <template>
              <!-- refuse_shop_status 0待审核;1审核通过;-1审核不通过 -->
              <el-button
                v-if="row.refund_status == 'APPLY' && row.after_sale_operate_allowable.allow_seller_approval && row.refuse_shop_status === 0"
                type="primary" size="mini" @click="examineRefund(row)">
                审核
              </el-button>
              <el-button v-if="row.refund_status == 'WAIT_SEND_BACK'" type="danger" size="mini"
                @click="handleSendback(row)">
                寄回
              </el-button>
              <el-button type="primary" size="mini" @click="handleRefundDetail(row)">
                查看
              </el-button>
              <el-button v-if="row.refund_status == 'APPLY' && row.refuse_shop_status == 0" type="danger" size="mini"
                @click="handleEdit(row)">
                修改
              </el-button>
              <el-button v-if="row.refund_status == 'APPLY' && row.refuse_shop_status == 0" type="danger" size="mini"
                @click="handleDelete(row)">
                取消
              </el-button>
            </template>
          </template>
        </el-table-column>
      </template>
      <!--分页-->
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="pageData.page_size" :total="pageData.data_total"></el-pagination>
    </en-table-layout>

    <!--审核-->
    <el-dialog-x-dialog title="审核" width="500px" :visible.sync="goodsRefundshow1">
      <el-form :model="refundInfo">
        <el-form-item label="售后单号" label-width="120px">
          {{ refundInfo.sn }}
        </el-form-item>
        <el-form-item label="关联订单" label-width="120px">
          {{ refundInfo.trade_sn }}
        </el-form-item>
        <el-form-item label="申请时间" label-width="120px">
          {{ refundInfo.create_time | unixToDate }}
        </el-form-item>
        <el-form-item label="售后状态" label-width="120px">
          {{ refundInfo.refund_status_text }}
        </el-form-item>
        <el-form-item label="服务类型" label-width="120px">
          {{ refundInfo.refuse_type_text }}
        </el-form-item>
        <el-form-item label="申请原因" label-width="120px">
          {{ refundInfo.refund_reason }}
        </el-form-item>
        <el-form-item label="问题描述" label-width="120px">
          {{ refundInfo.customer_remark }}
        </el-form-item>
        <el-form-item label="图片凭证" label-width="120px">
          <div style="flex-wrap: wrap; width: 100%; align-content: flex-start;">
            <el-image v-for="( item, index ) in  refund_img " :key="index" style="width: 100px; height: 100px"
              :src="item" :preview-src-list="refund_img">
            </el-image>
          </div>
        </el-form-item>
        <el-form-item label="退货方式" label-width="120px">
          <span v-if="refundInfo.refund_ship_type == 1">客户发货</span>
          <span v-if="refundInfo.refund_ship_type == 2">上门取件</span>
        </el-form-item>
        <!-- 站点退款金额 -->
        <el-form-item label="退款金额" label-width="120px" v-if="shop_type === 8">
          {{ refundInfo.refund_shop_price }}
        </el-form-item>
        <!-- 供应商退款金额 -->
        <el-form-item label="退款金额" label-width="120px" v-if="shop_type === 2">
          {{ refundInfo.refund_supplier_price }}
        </el-form-item>
        <!-- 企业-退款金额 -->
        <el-form-item label="退款金额" label-width="120px" v-if="shop_type === 3">
          {{ refundInfo.refund_enterprise_price }}
        </el-form-item>
        <el-form-item label="审核" label-width="120px">
          <el-radio v-model="refund_type" label="1">通过</el-radio>
          <el-radio v-model="refund_type" label="2">拒绝</el-radio>
        </el-form-item>
        <el-form-item label="拒绝原因" label-width="120px" v-if="refund_type === '2'">
          <el-input style="width:300px" :autosize="{ minRows: 12, maxRows: 12 }" type="textarea" maxlength="200"
            v-model="refund_reason" placeholder="拒绝原因最多不超200字。"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="padding:20px 0;text-align:center">
        <el-button @click="goodsRefundshow1 = false">取 消</el-button>
        <el-button type="primary" @click="handleRefund1(refundInfo.sn)">确 定</el-button>
      </div>
    </el-dialog-x-dialog>

    <!--退款失败原因-->
    <el-dialog title="退款失败原因" :visible.sync="isShowRefundFailReason" width="17%">
      <div align="center">{{ refund_fail_reason }}</div>
    </el-dialog>
    <!--修改售后申请-->
    <Applyforaftersales :isEdit="true" :sellerId="sellerId" :isJDGoods="isJDGoods" title="修改售后申请"
      :formdata="goodsAuditForm" @cancel="cancel" @refresh="refresh" :isShow="editVisible">
    </Applyforaftersales>
    <!-- 寄回 -->
    <el-dialog-x-dialog title="寄回商品" :visible.sync="sendbackVisible">
      <el-form :model="sendbackForm" ref="sendbackForm" :rules="backRules" label-width="135px">
        <el-form-item label="选择物流" prop="customer_logi_id">
          <el-select @change="sendbackLogistics" v-model="sendbackForm.customer_logi_id" placeholder="请选择物流">
            <el-option v-for=" item  in  logisticsData " :key="item.logi_id" :label="item.name"
              :value="item.logi_id"></el-option>
          </el-select>
          <input type="text" style="display: none" v-model="sendbackForm.customer_logi_name" />
        </el-form-item>
        <el-form-item label="物流单号" prop="customer_ship_no">
          <el-input style="width:240px" maxlength="50" placeholder="请输入物流单号"
            v-model="sendbackForm.customer_ship_no"></el-input>
        </el-form-item>
        <el-form-item label="收货人手机号" prop="customer_ship_mobile">
          <el-input style="width:240px" maxlength="11" placeholder="请输入收货人手机号"
            v-model="sendbackForm.customer_ship_mobile"></el-input>
        </el-form-item>
        <div class="dialog-footer" style="padding:20px 0;text-align:center">
          <el-button @click="e => sendbackVisible = false" size="small">取消</el-button>
          <el-button type="primary" @click="handlebackConfirm" size="small">确定</el-button>
        </div>
      </el-form>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import * as API_refund from '@/api/refund';
import * as API_order from '@/api/order';
import * as API_Goods from '@/api/goods';
import { handleDownload } from '@/utils';
import { Foundation } from '@/../ui-utils';
import { CategoryPicker } from '@/components';
import { RegExp } from '@/../ui-utils';
import * as API_logistics from '@/api/expressCompany';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
import Applyforaftersales from "@/components/Applyforaftersales";
export default {
  name: 'refundList',
  components: {
    EnTableLayout,
    [CategoryPicker.name]: CategoryPicker,
    Applyforaftersales
  },
  data () {
    return {
      sellerId: 0,
      isJDGoods: false,
      refund_tel: '',//供应商收货手机号
      sendbackVisible: false,//寄回弹窗
      sendbackForm: {
        sn: '',
        customer_logi_id: "",
        customer_logi_name: '',
        customer_ship_no: '',
        customer_ship_mobile: '',
      },
      backRules: {//寄回验证规则
        customer_logi_id: [
          { required: true, message: '请选择物流公司', trigger: 'change' }
        ],
        customer_ship_no: [
          { required: true, message: '请填写物流单号', trigger: 'blur' }
        ],
        customer_ship_mobile: [
          { required: true, message: '请填写收货人手机号', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error('手机号格式有误，请重新输入'))
              } else {
                callback()
              }
            }
          }
        ]
      },
      editVisible: false,//修改售后弹窗
      goodsAuditForm: {
        order_sn: "",
        sku_id: 0
      },
      refundstatusList: [
        { value: ' ', label: '全部' },
        { value: 'APPLY', label: '申请中' },
        { value: 'WAIT_SEND_BACK', label: '待寄回' },
        { value: 'SEND_BACK', label: '已寄回' },
        { value: 'COMPLETED', label: '已完成' },
        { value: 'REFUSE', label: '已拒绝' },
      ],
      getOrderNum: { apply: 0, waite_send_back: 0, send_back: 0 },//一件代发商城订单数量
      refund_type: '1',
      refund_reason: '',
      importLoading: false,
      wuliuForm: {},
      logiList: [],
      wuliuRules: {
        // 货物状态
        seller_logi_id: [
          {
            required: true,
            message: '请选择物流！',
            trigger: 'change'
          },
        ],
        seller_ship_no: [
          {
            required: true,
            message: '请输入物流单号',
            trigger: 'blur'
          },
        ],
      },
      type: '',
      typeTitle: '',
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
      },
      /** 列表数据 */
      tableData: null,
      /** 列表分页数据 */
      pageData: {
        page_no: 1,
      },
      /** 高级搜索数据 */
      advancedForm: {
        refuse_type: '',
        refund_status: ' ',
        self_type: 1,
        keyWord: 'sn',
        searchText: '',
      },
      shop_type: this.$store.getters.shopInfo.shop_type,
      /** 当前退款/货 单号 */
      currentSn: '',
      /** 是否显示退款失败原因 */
      isShowRefundFailReason: false,
      /** 退款失败原因 */
      refund_fail_reason: '',
      /** 当前退款/货 信息 */
      refundInfo: {},
      refund_img: [],
      /** 退款金额编辑 */
      refundMoney: '',
      /** 退款金额 */
      refundMoney1: '',
      // 退款积分
      refundPoint: '',
      /** 弹框是否显示 */
      goodsRefundshow: false,
      /** 审核备注 */
      remark: '',
      parentLogin: 0, //0 代表企业登录   1代表企业跳转商家
      goodsRefundshow1: false,
      /** 物流信息 */
      logisticsData: [],
      shop_id: this.$store.getters.shopInfo.shop_id,
    };
  },
  mounted () {
    const type = this.$route.query.type;
    if (type) {
      this.params['refund_status'] = 'APPLY'
      this.advancedForm.refund_status = 'APPLY'
    }
    API_Goods.getLoginType().then((res) => {
      this.parentLogin = res.parentLogin;
      this.GET_RefundOrder();
    });
    API_logistics.getExpressCompanyList({}).then((response) => {
      this.logiList = response;
    });
    this.getLogisticsCompanies()

  },
  watch: {},
  methods: {
    templateOrdernum (label) {
      if (label == "申请中") {
        return `(${this.getOrderNum.apply})`
      } else if (label == "待寄回") {
        return `(${this.getOrderNum.waite_send_back})`
      } else if (label == "已寄回") {
        return `(${this.getOrderNum.send_back})`
      } else {
        return ``
      }
    },
    /** 获取物流公司信息列表 */
    getLogisticsCompanies () {
      API_logistics.getExpressCompanyList({}).then((res) => {
        this.logisticsData = res;
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_RefundOrder();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_RefundOrder();
    },

    /** 高级搜索事件触发 */
    advancedSearchEvent () {
      const params = {};
      if (this.advancedForm.searchText) {
        params[this.advancedForm.keyWord] = this.advancedForm.searchText;
      }

      this.GET_RefundOrder(params);
    },

    /** 四种对应操作 显示不同的弹框内容 */
    handleOperateRefund (row) {
      this.getRefundDetails(row);
    },

    /** 打开审核弹窗 */
    examineRefund (row) {
      API_refund.getRefundDetails(row.sn).then((response) => {
        this.goodsRefundshow1 = true
        this.refundInfo = response.refund
        this.refundMoney = response.refund.refund_shop_price
        if (this.refundInfo.refund_img) {
          this.refund_img = this.refundInfo.refund_img.split(',')
        } else {
          this.refund_img = []
        }
      })
    },

    /** 审核操作 */
    handleRefund1 (sn) {
      if (this.refund_reason.length > 200) {
        this.$message.error('拒绝原因不得超过200个字符')
        this.goodsRefundshow1 = false;
      } else {
        const _params = {
          agree: this.refund_type,
          refund_shop_price: this.refundMoney,
          sn: sn,
          reason: this.refund_reason
        }
        console.log(_params)
        API_refund.refundAuth(sn, _params).then(() => {
          this.goodsRefundshow1 = false;
          this.GET_RefundOrder();
        });
      }
    },


    filterLogisticsData (row) {
      this.logisticsData.forEach((res) => {
        if (res.logi_id === row.logi_id) {
          row.logi_name = res.name;
        }
      });
    },

    /** 获取退款单列表数据 */
    async GET_RefundOrder (params = {}) {
      // 查询订单数量 
      this.getOrderNum = await API_order.getRefundOrderNum().then(res => res.data);
      this.loading = true;
      params = {
        ...this.params,
        ...params,
        refund_status: this.advancedForm.refund_status,
        refuse_type: this.advancedForm.refuse_type,
        self_type: this.advancedForm.self_type
      };
      let shopType;
      if (!params.searchText) {
        shopType = this.$store.getters.shopInfo.shop_type;
      }
      const setData = (resp) => {
        this.loading = false;
        this.pageData = {
          data: resp.data,
          page_no: resp.page_no,
          page_size: resp.page_size,
          data_total: resp.data_total,
        };
      }
      API_refund.getRefundList(params).then((response) => {
        setData(response);
      });
    },

    /** 显示退款失败原因 */
    showRefundFailReason (row) {
      this.isShowRefundFailReason = false;
      this.isShowRefundFailReason = true;
      this.refund_fail_reason = row.refund_fail_reason;
    },

    /** 查看退款详情 */
    handleRefundDetail (row) {
      this.$router.push({
        path: `/order/refund-detail/${row.sn}`
      })
    },

    //关闭申请售后弹窗
    cancel () {
      this.editVisible = false;
    },
    //售后申请后关闭弹窗刷新页面
    refresh () {
      this.GET_RefundOrder();
      this.editVisible = false;
    },
    /**修改售后订单 */
    handleEdit (row) {

      API_refund.getRefundDetails(row.sn).then(res => {
        this.sellerId = res.refund.seller_id;
        this.isJDGoods = res.refund.jd_order_id ? true : false;
        this.goodsAuditForm = {
          id: row.id,
          refund_sn: res.refund.sn,//售后订单号
          sku_id: res.refund_goods[0].sku_id,
          order_sn: res.refund.order_sn,
          refund_type: res.refund.refuse_type,//售后类型
          refund_reason: res.refund.refund_reason,//售后原因
          refund_desc: res.refund.customer_remark,//问题描述
          refund_img: res.refund.refund_img,//图片凭证
          refund_ship_type: res.refund.refund_ship_type,//退货方式
          //上门取件地址
          pickup_name: res.refund.pickup_name,//上门取件姓名
          pickup_mobile: res.refund.pickup_mobile,//上门取件手机号
          pickup_province_id: res.refund.pickup_province_id,//上门取件省id
          pickup_province: res.refund.pickup_province,//上门取件省
          pickup_city_id: res.refund.pickup_city_id,//上门取件市id
          pickup_city: res.refund.pickup_city,//上门取件市
          pickup_county_id: res.refund.pickup_county_id,//上门取件区id
          pickup_county: res.refund.pickup_county,//上门取件区
          pickup_town_id: res.refund.pickup_town_id,//上门取件镇id
          pickup_town: res.refund.pickup_town,//上门取件镇
          pickup_address: res.refund.pickup_address,//上门取件详细地址
          exchange_shipping_is_pickup: res.refund.exchange_shipping_is_pickup,//与上门取件地址保持一致
          //换货收货地址
          exchange_shipping_name: res.refund.exchange_shipping_name,//收货人姓名
          exchange_shipping_mobile: res.refund.exchange_shipping_mobile,//收货手机号
          exchange_shipping_province_id: res.refund.exchange_shipping_province_id,//收货省id
          exchange_shipping_province: res.refund.exchange_shipping_province,//收货省
          exchange_shipping_city_id: res.refund.exchange_shipping_city_id,//收货市id
          exchange_shipping_city: res.refund.exchange_shipping_city,//收货市
          exchange_shipping_county_id: res.refund.exchange_shipping_county_id,//收货区id
          exchange_shipping_county: res.refund.exchange_shipping_county,//收货区
          exchange_shipping_town_id: res.refund.exchange_shipping_town_id,//收货镇id
          exchange_shipping_town: res.refund.exchange_shipping_town,//收货镇
          exchange_shipping_address: res.refund.exchange_shipping_address,//收货详细地址

        }
        this.editVisible = true;
      })
    },
    /**取消售后操作 */
    handleDelete (row) {
      this.$confirm("确定要取消该商品的售后申请吗？", "提示", {
        confirmButtonText: '确定取消',
        cancelButtonText: '暂不取消',
        type: "warning"
      }).then(() => {
        console.log(row);
        API_refund.cancelRefundOrder(row.sn).then(res => {
          this.$message.success("取消成功");
          this.GET_RefundOrder();
        });
      });
    },
    /**打开寄回弹窗 */
    handleSendback (row) {
      //供应商后台配置的售后地址
      API_refund.sellerAddress(row.seller_id).then(res => {
        this.refund_tel = res.refund_tel;
        this.sendbackForm = {
          sn: row.sn,
          customer_logi_id: "",
          customer_logi_name: '',
          customer_ship_no: '',
          customer_ship_mobile: this.refund_tel
        }
        this.sendbackVisible = true;
      })
    },
    //物流公司change事件
    sendbackLogistics (el) {
      this.logisticsData.find(item => {
        if (el == item.logi_id) {
          this.sendbackForm.customer_logi_name = item.name;
        }
      })
    },
    /**寄回物品 */
    handlebackConfirm () {
      this.$refs.sendbackForm.validate((valid) => {
        if (valid) {
          const sendbackForm = {
            ...this.sendbackForm
          }
          API_refund.sendbackRefund(sendbackForm).then(res => {
            this.$message.success("寄回商品信息提交成功");
            this.sendbackVisible = false;
            this.GET_RefundOrder();
          })
        }
      })
    },
    /** 卖家审核退款/货 */
    handleRefundAuth (agree) {
      if (this.type !== 'goods1' && !RegExp.money.test(this.refundMoney)) {
        this.$message.error('请输入正确的退款金额');
        return;
      }
      const _tip = agree === 1 ? '通过' : '不通过';
      if (!agree) {
        if (!this.remark) {
          this.$message.error('请输入审核备注');
          return;
        }
      }
      this.$confirm(`确认${_tip}审核么?`, '确认信息', { type: 'warning' }).then(
        () => {
          const _params = {
            agree: agree,
            refund_shop_price: this.refundMoney1,
            refund_point: this.refundPoint,
            reason: this.remark,
          };
          API_refund.refundAuth(this.currentSn, _params).then(() => {
            this.goodsRefundshow = false;
            this.GET_RefundOrder();
            // this.$message.success("审核成功！");
          });
        }
      );
    },

    /** 卖家审核退款/货 */
    handleRefundAuth1 (agree) {
      if (!agree) {
        if (!this.remark) {
          this.$message.error('请输入审核备注');
          return;
        }
      }
      this.$confirm(`确认拒绝发货么?`, '确认信息', { type: 'warning' }).then(
        () => {
          const _params = {
            remark: this.remark,
            sn: this.currentSn,
          };
          API_refund.sellerShipRefuse(this.currentSn, _params).then(() => {
            this.goodsRefundshow = false;
            this.GET_RefundOrder();
          });
        }
      );
    },
    /** 发货 */
    handleRefundAuth2 (agree) {
      this.$refs['wuliuForm'].validate((valid) => {
        if (valid) {
          const params = JSON.parse(JSON.stringify(this.wuliuForm));
          this.$confirm(`确认发货么?`, '确认信息', { type: 'warning' }).then(
            () => {
              let logi_name = '';
              this.logiList.forEach((item) => {
                if (item.logi_id == params.seller_logi_id) {
                  logi_name = item.name;
                }
              });
              console.log(params.seller_logi_id);
              console.log(typeof params.seller_logi_id);
              params.seller_logi_name = logi_name;
              params.sn = this.currentSn;
              API_refund.sellerShip(this.currentSn, params).then(() => {
                this.goodsRefundshow = false;
                this.fhBoxShow = false;
                this.GET_RefundOrder();
              });
            }
          );
        } else {
          this.$message.error('表单填写有误，请核对！');
          return false;
        }
      });
    },

    /** 卖家执行退款 */
    handleRefund () {
      this.$confirm(`确认退款?`, '确认信息', { type: 'warning' }).then(() => {
        const _params = {};
        API_refund.toRefund(this.currentSn, _params).then(() => {
          this.goodsRefundshow = false;
          this.GET_RefundOrder();
        });
      });
    },

    /** 卖家执行入库操作 */
    handleWareHousing (row) {
      this.$confirm(`请确认是否收到货物，否则会钱财两空`, '操作', {
        type: 'info',
      }).then(() => {
        console.log(row)
        API_refund.wareHousing(row.sn).then(() => {
          this.GET_RefundOrder();
        });
      });
    },
    //导出
    submitImport () {
      this.importLoading = true;
      let orderFunc = 'getRefundList';
      let filterVals = [
        'goods_name',
        'sn',
        'trade_sn',
        'mobile',
        'remark',
        'create_time',
        'refuse_type_text',
        'refund_status_text',
      ];
      let tHeaders = [
        '商品名称',
        '售后单号',
        '订单编号',
        '下单账号',
        '下单备注信息',
        '申请时间',
        '类型',
        '状态',
      ];
      let params = { ...this.params };
      params.page_no = 1;
      params.page_size = 99999;
      params.self_type = this.advancedForm.self_type
      API_refund[orderFunc](params)
        .then((response) => {
          response.data.map((item, index) => {
            item.create_time = Foundation.unixToDate(
              item.create_time,
              'yyyy-MM-dd hh:mm:ss'
            );
          });
          handleDownload(response.data, tHeaders, filterVals, '退款订单');
          this.importLoading = false;
        })
        .catch((res) => {
          this.importLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.refund-list {
  .el-tabs__header {
    margin-bottom: 0;
  }
}

/deep/ .el-scrollbar__wrap {
  margin-right: -10px !important;
}
</style>
